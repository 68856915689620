<template>
  <div class="cloudappdeploy-container">
    <el-form ref="form" :model="deployform">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="应用名称">
            <el-input v-model="deployform.app_name" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="访问URL">
            <el-input v-model="deployform.app_accessurl" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-form-item label="应用描述">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="deployform.app_description"
          /> </el-form-item
      ></el-row>

      <el-row>
        <el-form-item label="容器编排文件">
          <prism-editor
            class="my-editor"
            v-model="composefile"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </el-form-item>

        <!-- <el-col :span="12">
          <el-form-item label="停止命令">
            <prism-editor
              class="my-editor"
              v-model="code_stop"
              :highlight="highlighter"
              line-numbers
            ></prism-editor>
          </el-form-item>
        </el-col> -->
      </el-row>

      <el-divider></el-divider>
      <el-row>
        <el-form-item label="镜像文件">
          <Gallery :uploader="uploader" />
        </el-form-item>
      </el-row>

      <!-- <editor-vue class="editor" v-model="deployform.app_startcmd"></editor-vue> -->
      <!-- <div>
        <tinymce-editor api-key="no-api-key" :init="init" v-model="text1" />
      </div> -->
      <!-- <el-divider></el-divider> -->

      <el-divider></el-divider>
      <el-row>
        <el-form-item>
          <el-button
            v-loading="listLoading"
            style="margin-left: 10px"
            type="primary"
            @click="publishDeploy"
          >
            保存
          </el-button>
          <el-button
            v-loading="listLoading"
            type="primary"
            plain
            @click="cancelDeploy"
          >
            取消
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <!--
      <el-form-item label="应用镜像文件">
        <FileInputComponent :uploader="uploader" />
      </el-form-item>
      <el-form-item
        label="应用镜像文件1"
        v-for="file in state.visibleFiles"
        :key="file.id"
      >
        <Thumbnail :id="file.id" :uploader="uploader" />
        <Filename :id="file.id" :uploader="uploader" />
        <DeleteButton :id="file.id" :uploader="uploader" />
      </el-form-item>
    </el-form>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 500px"
    >
      <el-table-column align="center" label="ID" width="80">
        <template slot-scope="{ row }">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="镜像文件名称" min-width="100">
        <template slot-scope="{ row }">
          <span>{{ row.appdockerimagename }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="Actions" width="120">
        <template slot-scope="{ row }">
          <el-button
            v-if="row.delete"
            type="success"
            size="small"
            icon="el-icon-circle-check-outline"
            @click="confirmDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "vue-fineuploader/gallery";
// import FileInput from "vue-fineuploader/file-input";
// import Thumbnail from "vue-fineuploader/thumbnail";
// import DeleteButton from "vue-fineuploader/delete-button";
// import Filename from "vue-fineuploader/filename";
// import FileInputComponent from "@/components/FileInputComponent";
import { deploycloudapp } from "../../services/api/deploycloudapp";
// import editor from "@/components/editor";
// import tinymce from "tinymce";
// import Editor from "@tinymce/tinymce-vue";

// import "../../../public/tinymce/themes/silver";
// import "../../../public/tinymce/icons/default/icons";
// import "../../../public/tinymce/themes/silver/theme.min";
// import "../../../public/tinymce/skins/ui/oxide/skin.min.css";
// import "../../../public/tinymce/plugins/paste/plugin.js";
// import "../../../public/tinymce/plugins/codesample/plugin.js";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

// const isFileGone = (statusToCheck, statusEnum) => {
//   return [statusEnum.CANCELED, statusEnum.DELETED].indexOf(statusToCheck) >= 0;
// };

export default {
  name: "cloudappdeploy",
  computed: {
    ...mapGetters(["name"]),
  },
  components: {
    Gallery,
    // FileInput,
    // Thumbnail,
    // DeleteButton,
    // Filename,
    // FileInputComponent,
    // "tinymce-editor": Editor,
    PrismEditor,
  },
  // mounted() {
  // tinymce.init({});
  // this.uploader.on("statusChange", this._onStatusChange);
  // },
  // destroyed() {
  //   this.uploader.off("statusChange", this._onStatusChange);
  // },
  methods: {
    publishDeploy() {
      const statusEnum = this.uploader.qq.status;
      const uploads = this.uploader.methods.getUploads({
        status: statusEnum.UPLOAD_SUCCESSFUL,
      });

      this.imagefiles = [];

      uploads.forEach(function (upload) {
        var imagefile = { file_name: upload.name, file_uuid: upload.uuid };
        this.imagefiles.push(imagefile);
      }, this);

      this.deployform.imagefiles = this.imagefiles;

      this.deployform.app_composefile = this.composefile;

      // this.deployform.app_startcmd = this.code_start;

      // this.deployform.app_stopcmd = this.code_stop;

      // console.log(this.code);
      // var content = tinymce.activeEditor.getContent({ format: "text" });

      // // " " not equal " "
      // console.log(content);
      // this.deployform.app_startcmd = content.replace(/ /gi, " ");
      // console.log(this.deployform.app_startcmd);

      // this.deployform.app_startcmd = tinymce.activeEditor.getContent({
      //   format: "text",
      // });

      deploycloudapp(this.deployform)
        .then((data) => {
          // this.list = data;
          var res = data.code;
          if (res === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.$router.push({path: '/cloudapplications/index'});
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    cancelDeploy() {
      const statusEnum = this.uploader.qq.status;
      const uploads = this.uploader.methods.getUploads({
        status: statusEnum.UPLOAD_SUCCESSFUL,
      });
      uploads.forEach(function (upload) {
        this.uploader.methods.deleteFile(upload.id);
      }, this);

      this.deployform.app_name = "";
      this.deployform.app_description = "";
      this.deployform.app_accessurl = "";
      this.deployform.app_composefile = "";
      this.composefile = "";
      // this.code_start = "";
      // this.deployform.app_stopcmd = "";
      // this.code_stop = "";

      this.$router.push({path: '/cloudapplications/index'});
    },
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
  },

  data() {
    var header_auth = `Bearer ${window.localStorage.getItem("access_token")}`;

    const uploader = new FineUploaderTraditional({
      options: {
        debug: true,
        request: {
          endpoint: `${process.env.VUE_APP_API_HOST}/api/upload/endpoint`,
          customHeaders: {
            Authorization: header_auth,
          },
        },
        chunking: {
          enabled: true,
          partSize: 1000000,
        },
        deleteFile: {
          enabled: true, // defaults to false
          endpoint: `${process.env.VUE_APP_API_HOST}/api/upload/endpoint`,
        },
      },
    });

    return {
      uploader,
      // text1: "",
      // text2: "",
      // state: {
      //   visibleFiles: [],
      // },
      // imagefiles: [],
      deployform: {
        app_name: "",
        app_description: "",
        app_accessurl: "",
        app_region: "beijing",
        app_status: "",
        app_resourceid: "",
        app_composefile: "",
        // app_startcmd: "",
        // app_stopcmd: "",
        imagefiles: [],
      },
      composefile: "",
      // code_start: " ",
      // code_stop: " ",
      // init: {
      //   // baseURL: "/public/tinymce",
      //   // suffix: ".min",
      //   // language_url: "/lib/js/zh_CN.js",
      //   // language: "zh_CN",
      //   height: 200,
      //   // plugins: "",
      //   // toolbar: "",
      //   toolbar: "codesample",
      //   menubar: false,
      //   statusbar: false,
      //   branding: false,
      //   plugins: "paste codesample",
      //   codesample_languages: [{ text: "YAML", value: "yaml" }],
      //   paste_auto_cleanup_on_paste: true,
      //   // paste_postprocess: function (pl, o) {
      //   //   // remove extra line breaks
      //   //   o.node.innerHTML = o.node.innerHTML.replace(/&nbsp;/gi, " ");
      //   //   console.log(o.node.innerHTML);
      //   // },
      // },
      // list: [
      //   {
      //     id: "1",
      //     appdockerimagename: "postgres-9.6-alpine.tar",
      //     delete: true,
      //   },
      // ],
      listLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.cloudappdeploy {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;

  color: #ccc;

  // margin: 5px, 30px;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
